<template>
    <div class="col col-12 pa-0 input-form variableInput" style="position: relative;">
        <span v-if="field.title" class="label"><b>{{ $t(field.title) }}</b><span v-if="field.props && field.props.required" style="color: red">&nbsp;*</span></span>
        <v-textarea
          v-model="textModel"
          :label="field.title ? $t(field.title) : ''"
          v-bind="field.props"
          :dense="false"
          :rules=[validate]
          single-line
          @keypress="inputVariable"
          class="pt-0 mt-0"
          :id="'inputVar_' + this.fieldName"
          :ref="'inputVariable' + this.fieldName"
          style="position: relative; z-index: 3;"
        >
            <template v-slot:append>
                <v-btn depressed text @click.stop="openTestMenu" class="square">
                    <v-icon dark small color="darkgrey">
                        $bracketscurly_d
                    </v-icon>
                </v-btn>
            </template>
        </v-textarea>
        <varMenu :menuItems="variables" :isLeft="isLeft" :left="left" :isTop="isTop" :isOffsetX="false" :hasTitle="field.title ? true : false" @menuVarClick="addVal" :isOffsetY="false" ref="varMenu" />

        <div class="d-flex justify-space-between">
            <!-- Si c'est du unicode, les sms ne font que 70 chars -->
            <div v-if="field.smsCountText" class="mt-2"><b :class="MsgNumber == 1 ? 'black--text': (MsgNumber > 7 ? 'error--text' : 'warning--text')">
                {{ $t(field.smsCountText) + " : " + MsgNumber }} <span v-if="MsgNumber > 7">({{ $t('tooManyCreditUsed') }})</span></b><br/>
                <b>{{ $t('ifmarketing') }} : {{ MsgNumberMarketing }}</b> ({{ $t('marketingSMSaddStopExplanation') }})
            </div>
            <div v-if="field.charactCountText" class="mt-2">
                {{ $t(field.charactCountText) + " : " + MsgRealLength }}
            </div>
        </div>

        <div v-if="field.smsCountText && model[fieldName] != '' && containsNonGsmCharacters(htmlEntityDecodeAndStripTags(model[fieldName]).trim())">
            {{ $t('smsUnicodeBecause') }} : 
            <span v-html="messageWithProblem(htmlEntityDecodeAndStripTags(model[fieldName]).trim())"></span>
        </div>
    </div>
</template>

<script>
import validate from '../functions/validations'
import varMenu from '@/components/varMenu'
import GenericDataService from '@/services/GenericDataService'

export default {
    props: ["model", "fieldName", "field", "contexts"],
    components: { varMenu },
    mixins: [validate],
    data() {
        return {
            show: false,
            variables : [],
            isLeft: false,
            isTop: false,
            cursorPosition: 0,
            srcInput: {},
            left: 10,
        };
    },
    created() {
        if(this.contexts && this.contexts.length > 0){
            let payload = {
                contexts : this.contexts
            }

            GenericDataService.postData('/template/getAvailableVariables', payload).then((response) => {
                this.variables = this.tradIfNeeded(response.data.data);
            })
        } else {
            GenericDataService.getData('/template/getAvailableVariables').then((response) => {
                this.variables = this.tradIfNeeded(response.data.data);
            })
        }
    },
    computed: {
        MsgNumber(){
            return Math.ceil(this.MsgRealLength / 
                (this.containsNonGsmCharacters(this.htmlEntityDecodeAndStripTags(this.model[this.fieldName]).trim()) 
                ? (this.MsgRealLength <= 70 ? 70 : 67) 
                : (this.MsgRealLength <= 160 ? 160 : 153)))
        },
        MsgNumberMarketing(){
            return Math.ceil((this.MsgRealLength + 11) / 
                (this.containsNonGsmCharacters(this.htmlEntityDecodeAndStripTags(this.model[this.fieldName]).trim()) 
                ? (this.MsgRealLength <= 70 ? 70 : 67) 
                : (this.MsgRealLength <= 160 ? 160 : 153)))
        },
        MsgRealLength(){
            return this.htmlEntityDecodeAndStripTags(this.model[this.fieldName]).trim().replace(/([\\\[\]\{\}~^\|€])/g, "--").length
        },
        textModel: {
            get() {
                // Transforme le contenu HTML en texte brut en conservant les sauts de ligne
                const htmlContent = this.model[this.fieldName];
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = htmlContent;

                // Remplace les balises <br> par des sauts de ligne
                tempDiv.querySelectorAll('br').forEach(br => br.replaceWith('\n'));

                return tempDiv.textContent || "";
            },
            set(value) {
                // Transforme le texte brut en contenu HTML
                this.model[this.fieldName] = value.replace(/\n/g, '<br />');
            }
        }
    },
    methods: {
         tradIfNeeded(data){
            data.forEach(element => {
                if(element.to_trad){
                    if(element.hasOwnProperty('name'))
                        element.name = this.$t(element.name)
                    else
                        element.text = this.$t(element.text)
                }
                if(element.hasOwnProperty('items'))
                   element.items = this.tradIfNeeded(element.items)
            });
            return data
        },
        openTestMenu(){
            let elementId = 'inputVar_' + this.fieldName;
            let fieldX = document.getElementById(elementId).getBoundingClientRect().x;
            let fullWidth = document.body.clientWidth;
            let fieldY = document.getElementById(elementId).getBoundingClientRect().y;
            let fullHeight = document.body.clientHeight;

            let percentTop = (fieldY*100)/fullHeight;
            if(percentTop > 55){
                 this.isTop = true
            } else {
                this.isTop = false
            }
            let percentLeft = (fieldX*100)/fullWidth;
            if(percentLeft > 75){
                this.isLeft = true
                this.left = 100
            } else {
                this.isLeft = false
                this.left = 10
            }
            setTimeout(() => {
                this.$refs.varMenu.show = true    
            }, 10);
        },
        inputVariable(e){
            if(e.key == '{'){
                this.cursorPosition = e.srcElement.selectionStart
                this.srcInput = e.srcElement
                setTimeout(() => {
                   this.openTestMenu() 
                }, 50);
            } 
            
        },
        addVal($event) {
            let count = 0;
            let value = $event.value
            if(this.model[this.fieldName] && this.model[this.fieldName].length > 0){
                let firstPart = this.model[this.fieldName].substring(0, (this.cursorPosition - 1))
                let secondPart = this.model[this.fieldName].substring(this.cursorPosition)
                if(firstPart.match(/{+$/)){
                    firstPart = firstPart.replace(/{+$/, value)
                } else {
                    firstPart = firstPart+value
                }
                
                count = firstPart.length;
                this.model[this.fieldName] = firstPart+secondPart;
            } else {
                count = value.length
                this.model[this.fieldName] = value;
            }
            this.cursorPosition = count + 1
            setTimeout(() => {
                const ctrl =  document.getElementById('inputVar_'+this.fieldName)
                if (ctrl.setSelectionRange) {
                    ctrl.focus();
                    ctrl.setSelectionRange(count, count);
                } else if (ctrl.createTextRange) {
                    var range = ctrl.createTextRange();
                    range.collapse(true);
                    range.moveEnd('character', count);
                    range.moveStart('character', count);
                    range.select();
                }
                this.$refs.varMenu.show = false
                this.$forceUpdate();
            }, 50);
        },
        htmlEntityDecodeAndStripTags(text) {
            if(text) {
                var strippedText = text.replace(/(<([^>]+)>)/gi, '');
                
                var textarea = document.createElement('textarea');
                textarea.innerHTML = strippedText;
                var decodedText = textarea.value;
            
                return decodedText;
            }
            
            return "";
        },
        containsNonGsmCharacters(str) {      
            var gsmRegex = /^[A-Za-z0-9\s\r\n@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!\"#¤%&'()+,-.\/:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\*\\\[~\]|€]+$/;
            return !gsmRegex.test(str);
        },
        messageWithProblem(str){
            return str.replace(/([^A-Za-z0-9\s\r\n@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!\"#¤%&'()+,-.\/:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\*\\\[~\]|€])/g, "<b style='color:red;'>[$1]</b>")
        },
    },
};
</script>

<style lang="scss">
</style>