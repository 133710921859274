<template>
  <v-dialog
    :retain-focus="false"
    no-click-animation
    v-model="dialog"
    :fullscreen="form.params.fullscreen"
    :width="!form.params.fullscreen ? (form.schema && form.schema.options && form.schema.options.width ? form.schema.options.width : '800') : ''"
    :hide-overlay="form.params.fullscreen"
    :transition="form.params.fullscreen ? 'dialog-bottom-transition' : 'fade-transition'"
    scrollable
    persistent
    @keydown.esc="cancel"
    content-class="vdialogForm"
  >
    <v-card tile>
      <v-toolbar flat class="bg-gradient" height="64">
        <v-btn x-large depressed color="transparent" class="ml-2" dark @click.native="cancel"><v-icon small class="mr-2" >$prev</v-icon> {{ $t('back') }}</v-btn>
        <!--v-toolbar-title v-if="dialog && form.schema.steps && form.schema.steps[form.schema.current_step - 1] && form.schema.steps[form.schema.current_step - 1].title">
          <span>{{ $t(form.schema.steps[form.schema.current_step - 1].title) }}</span>
        </v-toolbar-title>
        <v-toolbar-title v-else-if="dialog && form.schema.title">{{ $t(form.schema.title) }}</v-toolbar-title>
        <v-toolbar-title v-else-if="form.schema.options.title && form.schema.options.context">{{ $t(form.schema.options.title, form.schema.options.context) }}</v-toolbar-title>
        <v-toolbar-title v-else-if="title">{{ title }}</v-toolbar-title>

        <v-toolbar-title v-else>Formulaire</v-toolbar-title-->
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text :class="form.schema.options.steps ? 'pa-0 stepperLibTest': ''">
        <div v-if="form.schema.options && form.schema.options.banner" class="container container--fluid pb-0">
            <v-row class="justify-center">
                <v-col cols="12">
                    <div class="banner" style="position: relative;">
                        <div class="imgContent mr-6" style="position: absolute; top: 10px;">
                            <v-img :src="publicPath + 'img/echange.png'" height="75" max-width="75" contain></v-img>
                        </div>
                        <div class="imgContent mr-6" style="position: absolute; bottom: 0; ">
                            <v-img :src="publicPath + 'img/doigt-leve.png'" height="75" max-width="75" contain></v-img>
                        </div>
                        <div class="imgContent mr-6" style="position: absolute; top: 10px; right:10px;">
                            <v-img :src="publicPath + 'img/megaphone.png'" height="75" max-width="75" contain></v-img>
                        </div>
                        <div class="imgContent mr-6" style="position: absolute; bottom: 0; right:30px;">
                            <v-img :src="publicPath + 'img/question.png'" height="75" max-width="75" contain></v-img>
                        </div>
                        <div class="content-text" style="width: 100%;">
                            <p class="bannerTitle text-center primary--text">{{$t(form.schema.options.banner.title)}}</p>
                            <p :class="(form.schema.options.banner.button ? 'text-center' : 'mb-0 text-center') + ' primary--text'" v-html="$t(form.schema.options.banner.comment)" ></p>
                            <p class="mb-0 text-center" v-if="form.schema.options.banner.button">
                                <v-btn depressed :class="(form.schema.options.banner.btn_color ? form.schema.options.banner.btn_color : 'bg-gradient') + ' white--text'" :href="form.schema.options.banner.button.url" target="_blank" rounded>{{$t(form.schema.options.banner.button.label)}}</v-btn>
                            </p>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
        <h5 v-if="form.schema && form.schema.title" class="text-center mt-6">{{ $t(form.schema.title) }}</h5>
        <h6 v-if="form.schema && form.schema.subtitle" class="text-center">{{ $t(form.schema.subtitle) }}</h6>
        <div v-if="form.schema && form.schema.alert && form.schema.alert.hasOwnProperty('text')">
        <v-alert border="left" class="mt-4 mb-0" text color="error">
            <b>{{$t(form.schema.alert.text)}}</b>
        </v-alert>
        </div>
        <div v-if="form.schema && form.schema.impacts && Object.keys(form.schema.impacts).length" class="container container--fluid mt-3 pb-0" style="font-size:14px;">
          <h6>{{ $t(form.schema.impactsIntro) }}</h6>
          <ul v-if="form.schema.impacts" :class="form.schema.impactsRemoveListStyle ? 'noListStyle' : ''">
            <li v-for='(impact, index) in form.schema.impacts' :key="index"><span v-if="impact.nb != null">{{impact.nb}} </span>{{impact.to_trad ? $t(impact.label) : impact.label}}</li>
          </ul>
        </div>
        <FormStepperComponent v-if="dialog && form.schema.steps" :formSteps="form" @saveForm="submit" :action="action" @close="cancel(true)" ref="formComponent"/>
        <FormStepperNewComponent v-else-if="dialog && form.schema.options.steps" :form="form" @saveForm="submit" :action="action" @close="cancel(true)" @forceClose="cancel(true, true)" @launchFunction="reset($event)" ref="formComponent" :isFromLibraryPage="isFromLibraryPage"/>
        <FormQualifSuperleadComponent :saveAtInput="true" v-else-if="dialog && form.schema.options.qualifForm" :form="form" @saveForm="submit" :action="action" @close="cancel" @launchFunction="reset($event)" ref="formComponent" :isFromLibraryPage="isFromLibraryPage"/>
        <FormComponent v-else-if="dialog" :form="form" @saveForm="submit" :action="action" @close="cancel" @launchFunction="reset($event)" ref="formComponent" :isFromLibraryPage="isFromLibraryPage"/>
      </v-card-text>
      <div :class="form.schema.options.class ? form.schema.options : (form.schema.options.steps ? 'pa-0 stepperLibTest': '')" style="flex: 1 1 auto"></div>
    </v-card>
    <v-dialog persistent width="400" v-model="dialogExport">
      <v-card>
          <v-card-text class="pa-6">
            <h5 class="icon-title">
              {{$t('downloadReady')}}
              <v-btn @click="dialogExport = false" text class="square">
                <v-icon small>$close</v-icon>
              </v-btn>
            </h5>
            <div class="text-center mt-4 mb-2">
              <a :href="exportUrl" @click="$emit('exported'); dialogExport = false;" download class="exportBtn">{{$t('clickForDownload')}}</a>
            </div>
            
          </v-card-text>
      </v-card>
    </v-dialog>
    <FormDialogComponent
        v-if="anotherForm"
        ref="anotherAnotherForm"
        :form="anotherForm"
        :action="'SET_DATA'"
      />

    <v-dialog v-model="confirmExitDialog" max-width="700">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t('checkBeforeExitFormWithoutSave') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn depressed text class="square" @click="confirmExitDialog = false">
            <v-icon small> $close </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-actions class="py-6">
          <v-spacer></v-spacer>
          <v-btn depressed color="lightgrey"@click="cancel(true, true)">{{ $t('exitWithoutSave') }}</v-btn>
          <v-btn depressed color="info" @click="callChildSaveFunction">{{ $t('saveAndExit') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import FormComponent from "@/components/forms/FormComponent";
import FormStepperComponent from "@/components/forms/FormStepperComponent";
import FormStepperNewComponent from "@/components/forms/FormStepperNewComponent";
import FormQualifSuperleadComponent from "@/components/forms/FormQualifSuperleadComponent";

export default {
  name: "FormDialogComponent",
  components: {
    FormComponent,
    FormStepperComponent,
    FormStepperNewComponent,
    FormQualifSuperleadComponent,
    FormDialogComponent: () =>
      import("@/components/dialogs/FormDialogComponent"),
  },
  props: ['form', 'action', 'type', 'isFromLibraryPage', 'referenceForm'],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      api: process.env.VUE_APP_API_URL,
      dialog: false,
      resolve: null,
      title: null,
      anotherFormUrl: "",
      anotherForm: null,
      model: [],
      dialogExport:false,
      exportUrl: "",
      confirmExitDialog: false,
    };
  },
  methods: {
    reset(event) {
      this.$emit('launchFunction', event)
      this.resolve(true)
      this.$store.dispatch('forms/CLEAR_FORM', this.form.reference)
      this.dialog = false;
    },
    open(title) {
      this.dialog = true;
      this.title = title;
      if(this.$refs.formComponent && this.$refs.formComponent.$refs.selectCustom && this.$refs.formComponent.$refs.selectCustom.items.length > 0 && this.type != 'edit'){
        this.$refs.formComponent.$refs.selectCustom.items = []
        this.$refs.formComponent.$refs.selectCustom.loadingData = null
        this.$refs.formComponent.$refs.selectCustom.select="0"
      }
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    submit(response) {
      if(response == 'closeDialog'){
          this.dialog = false;
          this.$store.dispatch('forms/CLEAR_FORM', this.form.reference);
      } else {
        this.model = response.data.data;
        if(this.form.schema.options.otherFormUrl) {
          this.anotherFormUrl = this.form.schema.options.otherFormUrl;
          this.getFormToAdd();
        } else if(response.saveAndClose) {

          if(this.model && this.model.hasOwnProperty('directDownload')) {
            // var link = document.createElement("a");
            // link.setAttribute("href", this.api + response.data.data.directDownload);
            // let id = 'export_'+Date.now();
            // link.id = id;
            // link.setAttribute("download", "");
            // link.setAttribute("target", "_blank");
            // link.click();

            this.exportUrl = this.api + response.data.data.directDownload;
            this.dialogExport = true;

          } else {
            this.resolve(response);
            this.dialog = false;
            this.$store.dispatch('forms/CLEAR_FORM', this.form.reference);
          }

          
        } else {
          this.resolve(response);
        }
      }
      
    },
    cancel(reload = false, forceExit = false) {
        let oldModel = JSON.stringify(this.form.base_model);
        let newModel = JSON.stringify(this.form.model);

        if(this.form.schema.options.confirmBeforeReturn && (oldModel != newModel) && !forceExit) {
            this.confirmExitDialog = true;
        } else {
            this.resolve(false);
            this.dialog = false;
            this.confirmExitDialog = false;
            this.$store.dispatch('forms/CLEAR_FORM', this.form.reference)
            if(reload)
                this.$emit("refreshData");
        }

        this.$emit('cancelFormDialogComponent');
    },
    // Permet de remplacer les filtres dans l'URL Ajax par les valeurs du model
    replaceFilter(match, fieldName) {
      return this.model[fieldName];
    },
    //Initialisation de la popup d'add
    getFormToAdd(response) {
      this.$store
        .dispatch("forms/GET_FORM_DATA", {
          url: this.anotherFormUrl.replace(/{{(.*?)}}/g, this.replaceFilter),
        })
        .then((e) => {
          this.anotherForm = this.$store.state.forms.forms[e.reference];
        });
      setTimeout(() => {
        this.crtFromAnotherForm();
      }, 300);
    },
    async crtFromAnotherForm() {
      let response = await this.$refs.anotherAnotherForm.open(this.anotherForm.params.title);

      this.resolve(response);
      this.dialog = false;
      this.$store.dispatch('forms/CLEAR_FORM', this.form.reference)
    },
    callChildSaveFunction() {
        if(this.form.schema.options.steps)
            this.$refs.formComponent.checkTopolOrValidateForm(true);
        else
            this.$refs.formComponent.validateForm(true);
        this.confirmExitDialog = false;
    }
  },
  
};
</script>

<style lang="scss">
    .v-dialog {
      overflow:hidden !important;
    }
    .banner {
        background-color:var(--v-pale_purple-base);

        border-radius: 6px;

        width:100%;
        padding: 0px 40px;
        display:flex;
        align-items: stretch;

        .imgContent{
            display: flex;
            align-items: flex-end;
        }

        .content-text{
            color:#ffffff ;
            padding:35px;
            display:flex;
            flex-direction: column;
            justify-content: center;

            p{
                font-size:14px;
                line-height: 20px;
            }
            .bannerTitle{
                font-size:23px;
                font-weight: 800;
            }
        }
    }
    .stepperLibTest {
        min-height:calc(100% - 64px);
        //position:relative;
        background-color: var(--v-lightgrey-base);
    }
    .exportBtn{
      background-color:var(--v-primary-base);
      padding: 10px;
      border-radius:5px;
      color:white !important;
    }
    .noListStyle {
        list-style-type: none;
        padding-left: 0 !important;
    }
</style>
