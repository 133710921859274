<template>
    <div class="form" :class="form.schema.options.formClass ? form.schema.options.formClass : ''">
        <v-skeleton-loader v-if="!form.schema" type="article,article,actions"></v-skeleton-loader>
        <div class="firstDiv" v-else>
            <v-card-text class="pa-0 stepperLib" style="">
                <template v-if="Object.keys(form.schema.options.steps).length > 1">
                    <v-card>
                        <v-tabs v-model="stepper" background-color="transparent" centered height="60">
                            <v-tab v-for="(step, index) in form.schema.options.steps" :href="'#'+index" :key="index">
                                <v-icon small left>{{ step.icon }}</v-icon>
                                {{ $t(step.title) }}
                            </v-tab>
                        </v-tabs>
                    </v-card>
                </template>

                <v-card class="ma-3" style="" :class="form.schema.options.class ? form.schema.options.class : ''">
                    <v-card-text style="height: 100%;">
                        <v-form ref="form" v-model="valid" @submit.prevent="checkTopolOrValidateForm" :key="key">
                            <v-tabs-items v-model="stepper" style="height:inherit">
                                <v-tab-item v-for="(step, index) in form.schema.options.steps" :value="index" :class="step.class ? step.class : ''" :key="index" style="height:inherit">
                                    <div class="row ma-0 objectClass" style="height:inherit">
                                        <div v-for="(section, sectionName) in getSectionsByStep(index)" v-if="section.step === index" :key="sectionName" :class="Object.keys(getSectionsByStep(index)).length > 1 ? ('row ma-0 pl-2 pt-2 col-' + (12 / Object.keys(getSectionsByStep(index)).length) + ' d-block section') : 'row ma-0 pt-2'">
                                            <span class="py-2 h4 title mb-6" v-if="Object.keys(getSectionsByStep(index)).length > 1">{{section.title ? $t(section.title) : ''}}</span>
                                            <div :class="field.class ? field.class : 'col col-12 pa-0'" v-for="(field, fieldName) in section.fields" :key="fieldName" v-if="field.component && !field.hide" style="height:inherit">
                                                <div class="row ma-0 objectClass">
                                                    <FormComponentList :ref="field.component + '__' + fieldName" :field="field" :fieldName="fieldName" :model="form.model" :key="keys[fieldName]" @landingChange="landingChange" @validateForm="validateForm" @saveOnlyTemplateEmail="saveOnlyTemplateEmail" :isFromLibraryPage="isFromLibraryPage" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-card-text>

            <SuccessErrorAlertComponent v-if="$store.state.alerts.message" />
            
            <v-layout row class="mt-2">
                <v-spacer></v-spacer>
                <div class="registerForm">
                    <span v-if="diffBetweenModels && form.schema.options.checkDiffBtn">
                        <v-btn color="white" v-for="(btn,index) in form.schema.options.checkDiffBtn" :key="'btn_'+btn.label" class="mt-1 mr-3" depressed outlined @click="customEventClicked(btn)">{{$t(btn.label)}}</v-btn>
                    </span>
                    <v-btn color="white" class="mt-1 mr-3" depressed outlined :loading="loadingSubmit" :disabled="disableConfirm" @click="checkTopolOrValidateForm(true)">{{ $t(form.params && form.params.buttonConfirmTitle ? form.params.buttonConfirmTitle : "formSave") }}</v-btn>
                </div>
            </v-layout>

            <v-dialog v-model="checkBeforeDialog" width='800' scrollable>
                <v-card>
                    <v-toolbar tile flat dark class="bg-gradient">
                        <v-toolbar-title>{{checkBeforeDialogTitle}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn outlined dark depressed @click.native="cancelDialog" class="ml-3">{{$t('cancel')}}</v-btn>
                        <v-btn outlined dark depressed @click="validateAction(true)" class="ml-3">{{$t('confirm')}}</v-btn>
                    </v-toolbar>
                    <v-card-text class="pt-4">
                        <v-row>
                            <v-col cols="12">
                                {{checkBeforeDialogText}}
                            </v-col>
                            <v-col cols="12" v-if="checkBeforeDialogField">
                                <Component :is="checkBeforeDialogField.component" :model="form.model" :fieldName="checkBeforeDialogField.fieldName" :field="checkBeforeDialogField" />
                            </v-col>
                            <v-col cols="12" v-if="checkBeforeDialogImpact">
                                <v-checkbox class="mt-0 pt-0" hide-details v-model="form.model['acceptImpact']" :label="$t('impactCheckBoxLabel')"></v-checkbox>
                            </v-col>
                            <v-col cols="12" v-if="checkBeforeDialogError">
                                <b class="red--text">{{ checkBeforeDialogError }}</b>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import SuccessErrorAlertComponent from "@/components/alerts/SuccessErrorAlertComponent";
import GenericDataService from '@/services/GenericDataService'
import { i18n } from "@/i18n";
import { mapActions } from "vuex";
import FormComponentList from "@/components/forms/FormComponentList";
import VBtnToggle from "@/components/forms/inputs/v-btn-toggle";

export default {
    name: "FormComponent",
    components: {
        SuccessErrorAlertComponent,
        FormComponentList,
        VBtnToggle,
    },
    props: ["form", "action", "isFromLibraryPage"],
    data() {
        return {
            valid: null,
            disableConfirm: false,
            loadingSubmit:false,
            key: 0,
            keys: [],
            stepper: "parameters",
            checkBeforeDialog: false,
            checkBeforeDialogImpact: false,
            checkBeforeDialogField: false,
            checkBeforeDialogTitle: "",
            checkBeforeDialogText: "",
            checkBeforeDialogError: false,
            base_model: {},
            diffBetweenModels: false,
        };
    },
    mounted() {
        this.showHideConditionalFields();
        if(!this.form.schema.options.steps.hasOwnProperty('parameters')){
            this.stepper = Object.keys(this.form.schema.options.steps)[0]
        }
        setTimeout(() => {
            this.refreshItems();
            Object.assign(this.base_model, this.form.model);  
            this.checkDiffModel();
        }, 200);
        
    },
    computed: {
        // base_model: function () {
        //     return Object.assign({}, this.form.model);
        // },
        model: function () {
            return Object.assign({}, this.form.model);
        }
    },
    watch: {
        stepper: {
            handler(newVal, oldVal) {
                //console.log(newVal, oldVal, this.form.schema.options.title, this.$refs)
                if(this.form.schema.options && this.form.schema.options.title && this.form.schema.options.title == 'contentBuilder'){
                    if(oldVal == 'conception'){
                        if(this.$refs && this.$refs.VCustomLanding__html)
                            this.$refs.VCustomLanding__html[0].$refs.html.destroyEditor()
                    } else {
                        if(this.$refs && this.$refs.VCustomLanding__html)
                            this.$refs.VCustomLanding__html[0].$refs.html.initLPEditor()
                    }
                }
            }
        },
        // Permet de supprimer la valeur du model lorsqu'une valeur dépend d'une autre (ex: Assignement => Vendeur attribué dépend du PDV)
        model: {
            handler(newModel, oldModel) {
                for (var fieldName in newModel) {
                    if(!oldModel.hasOwnProperty(fieldName) || oldModel[fieldName] !== newModel[fieldName]) {
                        for (var [sectionName, section] of Object.entries(this.form.schema.sections)) {
                            if(section.fields.hasOwnProperty(fieldName)){ // Permet de récupérer le champ qui vient d'être update
                                if(section.fields[fieldName].hasOwnProperty('depends_fields') || section.fields[fieldName].hasOwnProperty('conditional_fields') || section.fields[fieldName].hasOwnProperty('properties_for')) {
                                    if(section.fields[fieldName].hasOwnProperty('properties_for')){
                                        let fieldToFill = section.fields[fieldName].properties_for[0]
                                        if(this.model[fieldName]){ 
                                            section.fields[fieldToFill].props.items = section.fields[fieldName].props.items.filter((item) => this.model[fieldName].includes(item.value))
                                            this.keys[fieldToFill] = this.keys[fieldToFill] ? this.keys[fieldToFill] + 1 : 1;
                                        }
                                    }
                                    if(section.fields[fieldName].hasOwnProperty('depends_fields')) {
                                        for (var dependFieldName of section.fields[fieldName].depends_fields) {
                                            this.keys[dependFieldName] = this.keys[dependFieldName] ? this.keys[dependFieldName] + 1 : 1;
                                        }
                                    }
                                    if(section.fields[fieldName].hasOwnProperty('conditional_fields')) {
                                        this.showHideConditionalFields();
                                    }
                                    this.$forceUpdate();
                                }
                            }
                        }
                    }
                }
                this.checkDiffModel()
            },
            deep: true
        },
    },
    methods: {
        ...mapActions("alerts", ["setAlert"]),
        checkDiffModel(){
            let oldModel = JSON.stringify(this.base_model);
            let newModel = JSON.stringify(this.form.model);
            if(oldModel !== newModel){
                this.diffBetweenModels = true
            }
        },
        landingChange($evt){
            this.loadingSubmit = $evt
        },
        customEventClicked(btn){
            if(btn.method == "reinitToModel" ){
                Object.assign(this.form.model, this.base_model);
                this.$refs.VCustomLanding__html[0].$refs.html.destroyEditor()
                setTimeout(() => {
                    this.$refs.VCustomLanding__html[0].$refs.html.initLPEditor() 
                    Object.assign(this.base_model, this.form.model); 
                    this.diffBetweenModels = false
                    this.checkDiffModel();
                }, 100);
                
                //this.$forceUpdate();
            } else if(btn.method == "convertToModel"){
                console.log("convertToModel");
                GenericDataService.postData('/landingpagev2/convertToModel', {model: this.form.model}).then((response) => {
                    this.$emit('forceClose')
                    this.$store.dispatch('ADD_SNACK', { message : this.$t('landingModelCreated') }).then((e) => { this.$store.dispatch("REMOVE_SNACK") });
                });
            }
        },
        refreshItems(){
            for (var fieldName in this.form.model){
                for (var [sectionName, section] of Object.entries(this.form.schema.sections)){
                if(section.fields.hasOwnProperty(fieldName)){
                    if( section.fields[fieldName].hasOwnProperty('properties_for')){
                    let fieldToFill = section.fields[fieldName].properties_for[0]
                    if(this.form.model[fieldName]){ 
                        section.fields[fieldToFill].props.items = section.fields[fieldName].props.items.filter((item) => this.model[fieldName].includes(item.value))
                        setTimeout(() => {
                        this.keys[fieldToFill] = this.keys[fieldToFill] ? this.keys[fieldToFill] + 2 : 1;
                        this.$forceUpdate()
                        }, 200);
                        
                    }
                    }
                }
                }
            }
        },
        confirmAction(saveAndClose = false, url = false) {
            this.$store
                .dispatch(this.action, {
                    url: url ? url : this.form.schema.options.posturl,
                    data: this.form.model,
                })
                .then((response) => {
                    if(saveAndClose) {
                        response.saveAndClose = true;
                    }
                    setTimeout(() => {
                        this.disableConfirm = false
                    }, 200);
                
                    this.$emit("saveForm", response);
                }).catch((e) =>  {
                    if(e.data && e.data.errors) {
                        for (let errorKey in e.data.errors) {
                            for (let sectionKey in this.form.schema.sections) {
                                for (let fieldKey in this.form.schema.sections[sectionKey].fields) {
                                    if(e.data.errors[errorKey].field === fieldKey) {
                                        this.form.schema.sections[sectionKey].fields[fieldKey].props['error'] = true;
                                        this.form.schema.sections[sectionKey].fields[fieldKey].props['error-messages'] = this.$t(e.data.errors[errorKey].message);
                                    }
                                }
                            }
                        }
                        this.key += 1;
                    } else {
                        let errorMessage = e.data && e.data.errorMessage ? e.data.errorMessage : this.$t('errorAPIform');
                        this.setAlert({
                            message: errorMessage,
                            type: "error",
                            timeout: 3000
                        });
                    }
                    setTimeout(() => {
                        this.disableConfirm = false
                    }, 200);
                });
        },
        checkTopolOrValidateForm(saveAndClose = false, url = false) {
            this.disableConfirm = true
            
            let validateForm = true;

            // Pour save le(s) TOPOL
            for (const key in this.$refs) {
                let component = key.split('__')[0];
                let fieldName = key.split('__')[1];

                if(component === 'VCustomTopol') {
                    this.$refs[key][0].$refs[fieldName].saveIframe(false);
                    validateForm = false;
                }

                if(component === 'VCustomFormModel') {
                    this.$refs[key][0].$refs[fieldName].saveSurvey();
                    validateForm = false;
                }
            }

            if(validateForm) {
                this.validateForm(saveAndClose, url);
            }
        },
        saveOnlyTemplateEmail() {
            this.$store.dispatch(this.action, { url: this.form.schema.options.posturl + '&saveOnlyTemplateEmail=true', data: this.form.model }).then((response) => {
                this.form.model['id'] = response.data.data.id;
            });
        },
        validateForm(saveAndClose = false, url = false) {
            this.disableConfirm = true

            // Supprime les objects vides
            for (var fieldName in this.form.model) {
                let field = this.getFieldByFieldName(fieldName); // Récupère le field
                if(field && Array.isArray(this.form.model[fieldName])) {
                    this.form.model[fieldName] = this.form.model[fieldName].filter(function(value) {
                        // Check pour le VArray qu'on est bien tout les champs de remplis
                        if(field && field['component'] === 'VArray' && (Object.keys(value).length === 0 || Object.keys(value).some((k) => value[k] === "" || typeof(value[k]) === 'undefined')))
                            return false;
                        return true;
                    }, this);
                }
            }
            
            if (this.$refs.form.validate() && this.validateRequiredFields()) {
                if(this.form.schema.options.checkBefore) {
                    GenericDataService.postData(this.form.schema.options.checkBefore.url, this.form.model).then((response) => {
                        if(response.data.data && response.data.data.title) {
                            this.checkBeforeDialogTitle = this.$t(response.data.data.title);

                            if(response.data.data.var) {
                                this.checkBeforeDialogText = this.$t(response.data.data.text, {var: response.data.data.var});
                            } else 
                                this.checkBeforeDialogText = this.$t(response.data.data.text);
                            if(response.data.data.impact)
                                this.checkBeforeDialogImpact = true
                            if(response.data.data.field) {
                                this.checkBeforeDialogField = response.data.data.field
                                if(this.checkBeforeDialogField.default)
                                    this.form.model[this.checkBeforeDialogField.fieldName] = this.checkBeforeDialogField.default;
                            }

                            this.checkBeforeDialog = true;
                        } else {
                            this.confirmAction(saveAndClose, url)
                        }
                    }).catch((e) => {
                        this.loader = false
                    })
                } else {
                    this.confirmAction(saveAndClose, url)
                }
            } else {
                this.$refs.form.inputs.forEach(input => {
                    if(!input.valid && input.$parent.$options.propsData.fieldName)
                        this.stepper = this.getSectionTabByFieldName(input.$parent.$options.propsData.fieldName)
                });
                this.disableConfirm = false;
            }
        },
        validateRequiredFields() {
            for (var [sectionName, section] of Object.entries(this.form.schema.sections)) {
                if(!this.form.schema.options.hasOwnProperty('steps') || this.form.schema.options.steps.hasOwnProperty(section.step)) {
                    for (var [fieldName, field] of Object.entries(section.fields)) {
                        delete this.form.schema.sections[sectionName].fields[fieldName].errorMessage;

                        if(field && field.hasOwnProperty('props') && field.props.hasOwnProperty('required') && field.props.required && (this.form.model[fieldName] === "" || typeof(this.form.model[fieldName]) === 'undefined' || (Array.isArray(this.form.model[fieldName]) && this.form.model[fieldName].length === 0) || (typeof(this.form.model[fieldName]) === 'object' && this.form.model[fieldName] !== null && Object.keys(this.form.model[fieldName]).length === 0))) {
                            this.form.schema.sections[sectionName].fields[fieldName].errorMessage = "requiredField";
                            this.keys[fieldName] = this.keys[fieldName] ? this.keys[fieldName] + 2 : 1;
                            return false;
                        }
                    }
                }
            }

            return true;
        },
        getFieldByFieldName(fieldName) {
            for (var [sectionName, section] of Object.entries(this.form.schema.sections)) {
                if(section.fields.hasOwnProperty(fieldName)){ // Permet de récupérer le champ qui vient d'être update
                    return section.fields[fieldName];
                }
            }
            return null;
        },
        getSectionTabByFieldName(fieldName){
            for (var [sectionName, section] of Object.entries(this.form.schema.sections)) {
                if(section.fields.hasOwnProperty(fieldName)){ // Permet de récupérer le champ qui vient d'être update
                    return section.step;
                }
            }
            return null;
        },
        showHideConditionalFields() {
            let forcedHide = [];
      
            for (var [sectionName, section] of Object.entries(this.form.schema.sections)) {
                if(section.hasOwnProperty('fields')) {
                    for (var [fieldName, field] of Object.entries(section.fields)) {
                        if(field.hasOwnProperty('conditional_fields')) {
                            for (var [conditionalFieldsSectionName, conditionalFieldsSection] of Object.entries(field.conditional_fields)) {
                                for (var conditionalField of conditionalFieldsSection) {
                                    if(this.form.schema.sections[conditionalFieldsSectionName] && this.form.schema.sections[conditionalFieldsSectionName].hasOwnProperty('fields') && this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField]) {
                    
                                        if(this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].hasOwnProperty('conditional_of')) {
                                            var fieldCanBeVisible = true;
                                            for (var conditionalOf in this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of) {
                                                if(conditionalOf === fieldName) {
                                                    // Check sur la value
                                                    let fieldNameCondition = 'value'; // valeur par défaut
                                                    if(this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf].hasOwnProperty('field')){ //si field est défini on remplace value par un autre champ
                                                        fieldNameCondition = this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf].field
                                                    }
                                                    if(this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf].hasOwnProperty(fieldNameCondition)) {
                                                        if(typeof this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition] === 'string') {
                                                            if(this.model[fieldName] !== this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition]) {
                                                                fieldCanBeVisible = false;
                                                            }
                                                        } else if (typeof this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition] === 'object') {
                                                            if(!this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition].includes(this.model[fieldName])) {
                                                                fieldCanBeVisible = false;
                                                            }
                                                        } else if (typeof this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition] === 'boolean') {
                                                            let valueModel = typeof this.model[fieldName] === 'object' && this.model[fieldName] !== null ? (this.model[fieldName][fieldNameCondition] ? this.model[fieldName][fieldNameCondition] : null) : this.model[fieldName];

                                                            // Début -- Traitement custom pour afficher le nom du RP dans le titre Date de début et Fin sur le form opé maketing
                                                            if(this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf]['updateTitle'] && typeof this.model[fieldName] === 'object' && this.model[fieldName] !== null) {
                                                                this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].tmpTitle = this.model[fieldName][this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf]['updateTitle']];
                                                                this.keys[conditionalField] = this.keys[conditionalField] ? this.keys[conditionalField] + 1 : 1;
                                                            }
                                                            // Fin -- Traitement custom pour afficher le nom du RP dans le titre Date de début et Fin sur le form opé maketing

                                                            if(valueModel !== this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition]) {
                                                                if(typeof valueModel !== "undefined" || (typeof valueModel === "undefined" && this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf][fieldNameCondition] === true)) {
                                                                    fieldCanBeVisible = false;
                                                                }
                                                            }
                                                        }
                                                    } else if(Array.isArray(this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf])) {
                                                        let neededValues = this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].conditional_of[conditionalOf].reduce(function (filtered, field) {
                                                            if(field.value)
                                                                filtered.push(field.value);
                                                            return filtered;
                                                        }, []);

                                                        if(!neededValues.includes(this.model[fieldName]))
                                                            fieldCanBeVisible = false;
                                                    }
                                                }
                                            }
                                            if(!fieldCanBeVisible || forcedHide[conditionalField] === true) {
                                                this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].hide = true;
                                                forcedHide[conditionalField] = true;
                                            } else {
                                                this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].hide = false;
                                            }
                                        } else if(this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].hasOwnProperty('conditional_property')){
                                            this.form.schema.sections[conditionalFieldsSectionName].fields[conditionalField].properties.forEach(property => {
                                                if(property.hasOwnProperty('conditional_of')){
                                                    var propertyCanBeVisible = true;
                                                    for (var conditionalOf in property.conditional_of){
                                                        if(conditionalOf === fieldName) {
                                                            let fieldNameCondition = 'value';
                                                            if(property.conditional_of[conditionalOf].hasOwnProperty('value')){
                                                                if(typeof property.conditional_of[conditionalOf][fieldNameCondition] === 'string') {
                                                                    if(this.model[fieldName] !== property.conditional_of[conditionalOf][fieldNameCondition]) {
                                                                        propertyCanBeVisible = false;
                                                                    }
                                                                } else if (typeof property.conditional_of[conditionalOf][fieldNameCondition] === 'object') {
                                                                    if(!property.conditional_of[conditionalOf][fieldNameCondition].includes(this.model[fieldName])) {
                                                                        propertyCanBeVisible = false;
                                                                    }
                                                                } else if (typeof property.conditional_of[conditionalOf][fieldNameCondition] === 'boolean') {
                                                                    if(this.model[fieldName] !== property.conditional_of[conditionalOf][fieldNameCondition]) {
                                                                        if(typeof this.model[fieldName] !== "undefined" || (typeof this.model[fieldName] === "undefined" && property.conditional_of[conditionalOf][fieldNameCondition] === true)) {
                                                                            propertyCanBeVisible = false;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    if(!fieldCanBeVisible) {
                                                        property.hide = true;
                                                    } else {
                                                        property.hide = false;
                                                    }
                                                }
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        getSectionsByStep(index) {
            let sections = {};

            for (const section in this.form.schema.sections)
                if(this.form.schema.sections[section].step === index)
                    sections[section] = this.form.schema.sections[section];

            return sections;
        },
        validateAction(saveAndClose = false){
            this.checkBeforeDialogError = false
            if(this.checkBeforeDialogField) {
                if(!this.form.model[this.checkBeforeDialogField.fieldName])
                    this.checkBeforeDialogError = "fieldsAreRequired";
            }
            if(this.checkBeforeDialogImpact) {
                if(!this.form.model['acceptImpact'])
                    this.checkBeforeDialogError = "fieldsAreRequired";
            }
            if(!this.checkBeforeDialogError)
                this.confirmAction(saveAndClose);
        },
        cancelDialog(){
            this.checkBeforeDialogTitle = "";
            this.checkBeforeDialogText = "";
            this.checkBeforeDialog = false;
            this.disableConfirm = false;
        },
    },
};
</script>

<style lang="scss">
.form {
    overflow-y: scroll;
    .containerForm {
        overflow: hidden !important;
    }
    .section {
        .title {
            width: 100%;
            display: inline-block;
        }
    }
    .required {
        label.v-label:after {
            content: '*';
            color: var(--v-error-base);
            margin-left: 5px;
        }
    }
    .registerForm {
        position:absolute;
        right:13px;
        top:10px;
    }
}
.WorkflowForm {
    min-height:100vh;
    .firstDiv {
        height:calc(100vh - 80px);
    }
}
.WorkflowContainer {
    min-height: 100vh;
}
.WorkflowConception{
    min-height:calc(100vh - 80px);
    width:100%;
    .objectClass {
        height:100%;
    }
}
.LPConception, .contentbuildercontainer{
    min-height:calc(100vh - 200px);
    &.preview {
        min-height:auto;
    }
    width:100%;
    .objectClass {
        height:100%;
    }
}
.stepperLib {
    height:calc(100vh - 80px);
}
</style>
