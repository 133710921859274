<template>
    <div class="col col-12 pa-0 input-form v-match-fields">
        <span class="label pb-5"><b>{{ $t(field.title) }}</b><span style="color:red">&nbsp;*</span></span>

        <div v-if="Object.values(model[fieldName]).length > 0">
            <v-row  v-for="(linkField, index) in model[fieldName]" :key="index">
                <v-col cols="1">
                    <span class="label mt-3"><b>{{ field.formFields[index] }}</b><span style="color:red">&nbsp;*</span></span>
                </v-col>
                <v-col>
                    <v-select
                    :placeholder="$t('selectLexxyField')"
                    dense
                    flat
                    solo
                    clearable
                    :items="getLexxyFields(model[fieldName][index])"
                    v-model="model[fieldName][index]"
                    :rules=[required(model[fieldName][index])]
                    @change="$forceUpdate()"></v-select>
                </v-col>
            </v-row>
        </div>

        <div v-else>
            <v-alert class="mb-0" border="left" color="info" text dense>
                {{ $t("emptyFieldInfoMessage") }}
            </v-alert>
            <v-text-field :rules="[validate]" class="hiddenField pt-0 pl-3"></v-text-field>
        </div>

        <div class="py-5">
            <v-btn small depressed color="pink" dark @click="openLinkFieldsModal">
                {{ $t("linknewfield") }}
            </v-btn>
        </div>

        <VMatchFieldModal ref="linkFieldsModal" />
    </div>
</template>

<script>
import validate from '../../../functions/validations'
import required from '../../../functions/validations'
import VMatchFieldModal from "@/components/forms/inputs/custom/forms/v-match-fields-modal";

export default {
    name: "VMatchFields",
    components: { VMatchFieldModal },
    mixins: [validate, required],
    props: ["model", "fieldName", "field"],
    data() {
        return {
        };
    },
    created() {
        if (!this.model[this.fieldName] || Array.isArray(this.model[this.fieldName])) {
            this.$set(this.model, this.fieldName, {});
        }
    },
    methods: {
        async openLinkFieldsModal() {
            let model = {};

            for (const property in this.field.formFields)
                model[property] = this.model[this.fieldName].hasOwnProperty(property) ? true : false;

            if(await this.$refs.linkFieldsModal.open(model, this.field)) {
                for (const property in model) {
                    if(model[property]) {
                        if(!this.model[this.fieldName].hasOwnProperty(property))
                            this.$set(this.model[this.fieldName], property, null);
                    } else {
                        this.$delete(this.model[this.fieldName], property);
                    }
                }
            }
        },
        getLexxyFields(value) {
            let values = [];

            this.field.lexxyFields.forEach(element => {
                if(!Object.values(this.model[this.fieldName]).includes(element) || value === element)
                    values.push({"value": element, "text": this.$t(element)});
            });

            return values;
        }
    }
};
</script>

<style lang="scss">
    .v-match-fields {
        .hiddenField {
            .v-input__slot {
                display: none;
            }
        }
    }
</style>