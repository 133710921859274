<template>
  <Component :ref="fieldName" :is="field.component" :field="field" :fieldName="fieldName" :model="model" :contexts="field.contexts" v-on="$listeners" :isFromLibraryPage="isFromLibraryPage" />
</template>

<script>
import VArray from "@/components/forms/inputs/v-array";
import VArrayText from "@/components/forms/inputs/v-array-text";
import VAutocomplete from "@/components/forms/inputs/v-autocomplete";
import VCheckbox from "@/components/forms/inputs/v-checkbox";
import VCombobox from "@/components/forms/inputs/v-combobox";
import VCustomCombobox from "@/components/forms/inputs/v-custom-combobox";
import VCustomGluedFields from "@/components/forms/inputs/v-custom-glued-fields";
import VCustomSelect from "@/components/forms/inputs/v-custom-select";
import VDatetimePicker from "@/components/forms/inputs/v-datetime-picker";
import VDatePicker from "@/components/forms/inputs/v-date-picker";
import VTimePicker from "@/components/forms/inputs/v-time-picker";
import VFileInput from "@/components/forms/inputs/v-file-input";
import VPdfInput from "@/components/forms/inputs/v-pdf-input";
import VPhoneArray from "@/components/forms/inputs/v-phone-array";
import VPhoneInput from "@/components/forms/inputs/v-phone-input";
import VueTelInputVuetify from "@/components/forms/inputs/vue-tel-input-vuetify";
import VRadioGroup from "@/components/forms/inputs/v-radio-group";
import VRangeSlider from "@/components/forms/inputs/v-range-slider";
import VSelect from "@/components/forms/inputs/v-select";
import VSlider from "@/components/forms/inputs/v-slider";
import VSwitch from "@/components/forms/inputs/v-switch";
import VTextField from "@/components/forms/inputs/v-text-field";
import VTextFieldSearch from "@/components/forms/inputs/v-text-field-search";
import VCustomExportModel from "@/components/forms/inputs/v-custom-export-model";
import VCustomFormModel from "@/components/forms/inputs/v-custom-form-model";
import VVariableTextField from "@/components/forms/inputs/v-custom-variable-text-field";
import VVariableTextarea from "@/components/forms/inputs/v-custom-variable-textarea";
import VTextarea from "@/components/forms/inputs/v-textarea";
import VWysiwyg from "@/components/forms/inputs/v-wysiwyg";
import VCustomCampaignOutTemplateSelect from "@/components/forms/inputs/custom/campaigns/out/v-template-select";
import VCustomCampaignOutSegmentSelect from "@/components/forms/inputs/custom/campaigns/out/v-segment-select";
import VCustomCampaignOutContactListSelect from "@/components/forms/inputs/custom/campaigns/out/v-contactlist-select";
import VCustomSelectSearch from "@/components/forms/inputs/v-custom-select-search";
import VCustomTableForm from "@/components/forms/inputs/v-custom-table-form";
import VFileInputDirect from "@/components/forms/inputs/v-file-input-direct";
import VLayoutSelect from "@/components/forms/inputs/v-layout-select";
import VBtnToggle from "@/components/forms/inputs/v-btn-toggle";
import VCustomNumberByIcon from "@/components/forms/inputs/v-custom-numberByIcon";
import VCustomTemplateSelect from "@/components/forms/inputs/v-custom-template-select";
import VCustomLibrarySelect from "@/components/forms/inputs/v-custom-library-select";
import VCustomVariableSelect from "@/components/forms/inputs/v-custom-variable-select";
import VCustomTopol from "@/components/forms/inputs/v-custom-topol";
import VCustomSegment from "@/components/forms/inputs/v-custom-segment";
import VCustomWorkflow from "@/components/forms/inputs/v-custom-workflow";
import VCustomLandingPage from "@/components/forms/inputs/v-custom-lp";
import VCustomEntryPoint from "@/components/forms/inputs/v-custom-entrypoints";
import VCustomWorkflowsPicker from "@/components/forms/inputs/v-custom-workflows-picker";
import VCustomRetroplanning from "@/components/forms/inputs/v-custom-retroplanning";
import VCustomLibraryPicker from "@/components/forms/inputs/v-custom-library-picker";
import CustomVarComponent from '@/components/forms/inputs/v-custom-variable-model.vue';
import VCustomReassignContacts from "@/components/forms/inputs/v-custom-reassign-contacts";
import VCustomItemCar from "@/components/forms/inputs/v-custom-item-car";
import VCustomLanding from "@/components/forms/inputs/v-custom-landing.vue";
import VMatchFields from "@/components/forms/inputs/custom/forms/v-match-fields";

export default {
  name: "FormComponentList",
  components: {
    VArray,
    VAutocomplete,
    VCheckbox,
    VCombobox,
    VCustomCombobox,
    VCustomGluedFields,
    VCustomSelect,
    VDatetimePicker,
    VDatePicker,
    VFileInput,
    VPdfInput,
    VPhoneArray,
    VPhoneInput,
    VueTelInputVuetify,
    VRadioGroup,
    VRangeSlider,
    VSelect,
    VSlider,
    VSwitch,
    VTextField,
    VCustomExportModel,
    VCustomFormModel,
    VVariableTextField,
    VVariableTextarea,
    VTextarea,
    VWysiwyg,
    VCustomCampaignOutTemplateSelect,
    VCustomCampaignOutSegmentSelect,
    VCustomSelectSearch,
    VCustomTableForm,
    VFileInputDirect,
    VTimePicker,
    VLayoutSelect,
    VBtnToggle,
    VCustomCampaignOutContactListSelect,
    VCustomNumberByIcon,
    VCustomTemplateSelect,
    VCustomLibrarySelect,
    VCustomVariableSelect,
    VCustomTopol,
    VCustomSegment,
    VTextFieldSearch,
    VCustomWorkflow,
    VCustomLandingPage,
    VCustomEntryPoint,
    VCustomLibraryPicker,
    VCustomRetroplanning,
    VCustomWorkflowsPicker,
    CustomVarComponent,
    VArrayText,
    VCustomReassignContacts,
    VCustomItemCar,
    VCustomLanding,
    VMatchFields,
  },
  props: ["field", "fieldName", "model", "isFromLibraryPage"],
  data() {
    return {};
  },
  mounted() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
};
</script>

<style lang="scss">
</style>